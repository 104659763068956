import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FloatingMenuComponent } from "../../components/floating-menu/floating-menu.component";
import { HeaderComponent } from "../../components/header/header.component";
import { BackButtonComponent } from "../../components/back-button/back-button.component";
import { FormsModule } from "@angular/forms";
@NgModule({
  declarations: [HeaderComponent, FloatingMenuComponent, BackButtonComponent],
  exports: [HeaderComponent, FloatingMenuComponent, BackButtonComponent],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
